module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-158550786-1"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Minicrosser","short_name":"Minicrosser","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/img/favicon.png","icons":[{"src":"/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png","density":"0.75"},{"src":"/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png","density":"1.0"},{"src":"/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png","density":"1.5"},{"src":"/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png","density":"2.0"},{"src":"/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png","density":"3.0"},{"src":"/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png","density":"4.0"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
